const createRow = row => row.join(',') + '\r\n'


const saveCSV = (fileName, csv) => {
  let blob = new Blob([csv], { type: 'text/csv'})
  /*******************stackoverflow ****************/
  var hiddenElement = document.createElement('a');
  hiddenElement.href = URL.createObjectURL(blob)
  hiddenElement.target = '_blank';
  hiddenElement.download = fileName;
  hiddenElement.click();
}

export {
  createRow,
  saveCSV
}
