import { useState, useEffect } from 'react'
import { useMutation  } from '@apollo/react-hooks'
import { ADD_ACCOUNT  } from '../../queries/accounts'

const useAccountsSync = ({dbAccounts, gmbAccounts}) => {
  const [sync, setSyncAccounts ] = useState(true)
  const [accountIds, setAccountIds ] = useState(null)
  const [addAccount, addedAccountData ] = useMutation(ADD_ACCOUNT)

  const syncAccounts = () => {
    let existingAccounts = dbAccounts.data.allAccounts.map(({accountId}) => accountId)
    gmbAccounts.map( ({name, accountName, profilePhotoUrl}) => {
      if (!existingAccounts.includes(name)) {
        addAccount({
          variables: {
            name: accountName,
            accountId: name,
            photoUrl: profilePhotoUrl ? profilePhotoUrl : 'none'
          }
        })
      } 
    })   
  }


  /****************************************************************
   * Sync the database with the results from GMB API
  ****************************************************************/
  useEffect( () => {
    if(dbAccounts && dbAccounts.data && gmbAccounts && sync) {
      setSyncAccounts(false)
      syncAccounts()
    }
  }, [dbAccounts, gmbAccounts, sync])

  /****************************************************************
   * Create an object of accounts ids linking to the account names
   * ex.) { accounts/129412481290: database.id }
  ****************************************************************/
  useEffect( () => {
    if (dbAccounts.data && dbAccounts.data.allAccounts.length !== 0) {
      setAccountIds(Object.assign({}, ...dbAccounts.data.allAccounts.map(account => ({[account.accountId]: account.id}) )))
    }
  }, [dbAccounts.data])

  return { addedAccountData, accountIds }
}


export default useAccountsSync