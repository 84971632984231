import React, { useState, useEffect, useContext }  from 'react'
import AdminContext from '../../AdminContext'
import ReviewCard from './ReviewCard'
import { TablePagination, Toolbar, Typography, Button } from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import moment from 'moment'
import { createRow, saveCSV} from '../../utils/csvFunctions'
import { Loader } from '../../../Components/Loader'

const dayFilter = (day, oldDay) =>  moment(day).format('YYYYMMDD') >= oldDay

const onExportReviewsCSV = (fileName, reviews) => {
  // Init the table row
  let header = [
    'Website',
    'Location Name',
    'Address',
    'City',
    'State',
    'Zip',
    'Reviewer Name', 
    'Star Rating', 
    'Comment', 
    'Create Time', 
    'Update Time'
  ]
  let csv    = createRow(header)
  let row 
  reviews.map(
    ({
      comment,
      starRating,
      updateTime,
      createTime,
      reviewerDisplayName,
      location: { 
        locationName,
        addressLines,
        administrativeArea,
        postalCode,
        locality,
        websiteUrl
       },
    }) => {
      let created = moment.utc(createTime).format('MMMM Do YYYY, h:mm:ss a');
      let updated = moment.utc(updateTime).format('MMMM Do YYYY, h:mm:ss a');
      row = [websiteUrl, locationName, addressLines, locality, administrativeArea, postalCode,reviewerDisplayName, starRating, comment, created, updated]
      row = row.map(el => '"' + el + '"')
      csv += createRow(row)
  })
  saveCSV(fileName, csv)
}

const ReviewsTable = ({reviews}) => {
  const oneWeek = moment().subtract(7,'d').format('YYYYMMDD');
  let oneWeekLabel = moment(oneWeek).format('MM-DD-YYYY')
  const todayLabel = moment().format('MM-DD-YYYY')
  const { resync, db, gmb, gmb: {fetchNewReviews} } = useContext(AdminContext)
  const [ rowsPerPage, setRowsPerPage ] = useState(25)
  const [ page, setPage ] = useState(0)
  const [sortedReviews, setSortedReviews] = useState([])
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0) 
  }   

  const [fetchingReviews, setFetching] = useState(false)
  const [syncingReviews, setSyncing] = useState(false)

  useEffect( () => {
    if(!resync.reviewsLoading && !gmb.fetchingGmbReviews) {
      setFetching(false)
    }
  }, [gmb, resync])

  useEffect(() => {
      if(reviews && !sortedReviews.length !== 0)
        setSortedReviews(reviews.allReviews.sort((a, b) => moment(b.createTime) - moment(a.createTime)))
  }, [reviews, sortedReviews])

  return (
      <div>
          <Loader isLoading={fetchingReviews} />
          <Loader isLoading={syncingReviews} />
          <Toolbar className="table-header reviews-toolbar">
              <Typography color="inherit" variant="subtitle1">
              Reviews
              </Typography>
              <Toolbar>
                  <Button 
                      className="button-download"
                      aria-label="Download CSV" 
                      variant="contained"
                      color="primary"
                      startIcon={<CloudDownloadIcon />}
                      onClick={
                        () => onExportReviewsCSV(
                          "reviews_"+oneWeekLabel+"_to_"+todayLabel+".csv", 
                          sortedReviews.filter(
                            ({createTime}) => dayFilter(createTime, oneWeek))
                          )
                      }
                  >
                      7 Days
                  </Button>
                  <Button 
                      className="button-download"
                      aria-label="Download CSV"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudDownloadIcon />}
                      onClick={() => onExportReviewsCSV("all_reviews.csv", sortedReviews)}
                  >
                      All
                  </Button>
                  <Button
                      className="button-download"
                      aria-label="Sync list"
                      variant="contained"
                      color="primary"
                      startIcon={<SyncIcon />}
                      onClick={ async() => {
                        setSyncing(true)
                        await fetchNewReviews()
                        setSyncing(false)
                        resync.setSyncReviews(true, setSyncing)
                      }}
                  >
                      Sync
                  </Button>
              </Toolbar>
          </Toolbar>
          {sortedReviews.length === 0 &&
            <Loader isLoading={true} />
          }
          { sortedReviews && 
            sortedReviews.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( 
              review => <ReviewCard key={review.id} {...review} /> 
          )}
          {sortedReviews && sortedReviews.length === 0 && <span className="wcn-message"> No Reviews found. </span>}

          <TablePagination 
              component="div"
              className="wcn-reviews__pagination"
              rowsPerPageOptions={[10, 25, 50, 100]}
              page={page}
              onChangePage={ (e, newPage) => setPage(newPage)}
              count={sortedReviews ? sortedReviews.length : 0}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </div>
  )

}

export default ReviewsTable
