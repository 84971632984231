import { useQuery }      from '@apollo/react-hooks'
import { ALL_REVIEWS, ALL_AUTH_REVIEWS   } from '../queries/reviews'
import { ALL_ACCOUNTS  } from '../queries/accounts'
import { ALL_LOCATIONS, ALL_AUTH_LOCATIONS } from '../queries/locations'


const useDB = (userId) => {
  const accounts = useQuery(ALL_ACCOUNTS)

  const locations = useQuery(ALL_AUTH_LOCATIONS, {
    variables: {
      userId: userId
    }
  })

  const reviews = useQuery(ALL_AUTH_REVIEWS, {
    pollInterval: 10000,
    variables: {
      userId: userId,
    }
  })

  return { accounts, locations, reviews }
}

export default useDB