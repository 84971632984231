import React, { useReducer, Fragment } from 'react';
import ApolloClient       from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks'
import { Redirect, Route, Switch, BrowserRouter as Router } from "react-router-dom"
import CssBaseline from '@material-ui/core/CssBaseline';
import HomePage from './PublicRoutes/Homepage'
import SignUpPage from './PublicRoutes/SignUp'
import PublicGoogleHeader from './Components/GoogleHeader'
import AdminApp from './PrivateRoutes/AdminApp'
import { AdminSidebar as Sidebar } from './PrivateRoutes/AdminSidebar'
import { useCookies } from 'react-cookie'
import './css/style.scss'

const client = new ApolloClient({
  uri: '/admin/api'
})

const AppLoginReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {...state, redirect: "home"}
    case 'setAuthentication':
      return { ...state, token: action.token, redirect: action.redirect, tokenInfo: action.tokenInfo, newSignup: action.newSignup }
    case 'resetLogin':
      return { confirming: false, token: null, redirect: null, tokenInfo: null }
    case 'setAuthenticationConfirming':
      return { confirming: true, token: action.token, redirect: action.redirect, tokenInfo: action.tokenInfo }
    default:
      throw new Error('Unknown action type given in AppLoginReducer')
  } 
}

const App  = ({auth}) => {
  const [ LoginState, dispatch ] = useReducer(AppLoginReducer, {
    token: auth ? auth.token : null,
    redirect: auth ? 'registered' : 'home',
    confirming: false,
    tokenInfo: auth ? auth.tokenInfo : null,
  })

  let script = document.createElement("script")
  script.src = "https://apis.google.com/js/platform.js"
  document.body.appendChild(script)

  return (
    <ApolloProvider client={client}>
      <Router>
        <CssBaseline />
        <PublicGoogleHeader dispatch={dispatch} token={LoginState.token} />
        <Switch>

          {/********************************* SIGNUP PAGE   *********************/}
          <Route exact path="/signup">
            <SignUpPage dispatch={dispatch} LoginState={LoginState} />
          </Route>

          {/********************************* HOMEPAGE   *********************/}
          { !LoginState.token && 
            <Route path="/">
              <HomePage script={script} dispatch={dispatch} /> 
            </Route>
          }

          { LoginState.redirect !== "new" && LoginState.tokenInfo &&  
            <Route path="/">
                <Fragment>
                    <Sidebar />
                    <AdminApp dispatch={dispatch} LoginState={LoginState} /> 
                </Fragment>
            </Route>
          }
            { !LoginState.token &&
            <Route path="/">
              <HomePage script={script} dispatch={dispatch} /> 
            </Route>
            }

        </Switch>
        { LoginState.redirect === "new" && <Redirect to="/signup" />  }

      </Router>
    </ApolloProvider>
  );
}

export default App;
