import React from 'react'
import {Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon  from '@material-ui/icons/Home';
import StarsIcon from '@material-ui/icons/Stars';
import RoomIcon from '@material-ui/icons/Room';


const AdminSidebar = () => {
  return(
    <Drawer 
        className="wcn-gmb__sidebar"
        variant="permanent"
        anchor="left"
    >
        <List component="nav">
            <ListItem button component={Link} to="/">
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            {/* <ListItem button component={Link} to="/accounts">
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary="Accounts" />
            </ListItem> */}
            <ListItem button component={Link} to="/locations">
                <ListItemIcon><RoomIcon /></ListItemIcon>
                <ListItemText primary="Locations" />
            </ListItem>
            <ListItem button component={Link} to="/reviews">
                <ListItemIcon><StarsIcon /></ListItemIcon>
                <ListItemText primary="Reviews" />
            </ListItem>
        </List>
    </Drawer>
  )
}

export { AdminSidebar }
