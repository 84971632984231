/****************************************************************
* @description: A generalized mutation hook to run query batching
*               on a mutation with a createinputname schema 
* @query_example
*   An example query that can be used is:

*   mutation CreateLocations($locations: [LocationsCreateInput!]) {
*     createLocations(data: $locations ) {
*       id
*     }
*   }
*
*  where createInputName in this case is 'locations'
****************************************************************/
import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'

const useMutationBatching = ({mutation, createInputName, batch_size}) => {
  const [query, setQuery] = useState([])
  const [addMutation, {data, loading} ] = useMutation(mutation)

  /********************************************************************
  * Post Batch to the mutation at the given
  * create input name specified in the mutation schema 
  ********************************************************************/
  useEffect( () => {
    if (query.length != 0) {
      let queryBatch = query.length <= batch_size ? query : query.slice(0, batch_size)
      addMutation({
        variables: {
          [createInputName]: queryBatch
        }
      })
    }
  }, [query])

  /********************************************************************
  * Check if a batched has finished posting. Then query next batch 
  ********************************************************************/
  useEffect(() => {
    if(data && !loading && query.length > batch_size)
      setQuery(oldquery => oldquery.slice(batch_size, oldquery.length))
  }, [data, loading, query])

  return { setQuery, queryData: data, queryLoading: loading }
}

export default useMutationBatching