import '../css/Private/style.scss'
import React, { useState } from 'react'
import { Redirect, Route, Switch } from "react-router-dom"
import { Dashboard } from './Dashboard/Dashboard'
import { Locations } from './Locations/Locations'
// import { Accounts } from './Accounts/Accounts'
// import { Account } from './Accounts/Account/Account'
import { Reviews } from './Reviews/Reviews'
import { Location } from './Locations/Location/Location'
import AdminContext from './AdminContext'
import useDB from './hooks/useDB'
import useGmb from './hooks/useGmb' 
import useSynchronizer from './hooks/synchronizer/useSynchronizer'
import { Container } from '@material-ui/core'
import SyncPage from './SyncPage/SyncPage'


const AdminApp = ({LoginState}) => {
  const [ currentPage, setCurrentPage ] = useState('/dashboard')
  const [ newUser, setNewUser ] = useState(LoginState.newSignup ? true : false)
  const db     = useDB(LoginState.token.userid)
  const gmb    = useGmb(LoginState)
  const resync = useSynchronizer({
    db, 
    gmb, 
    userId: LoginState.token.userid,
    newSignup: newUser,
    setNewUser: setNewUser
  })

  return (
    <AdminContext.Provider value={{LoginState, currentPage, setCurrentPage,gmb, db, resync}}>
      <div className="wcn-gmb__page">
        <Container maxWidth={false}>
          <Switch>
            {/********************************* Initial Syncing PAGE   *********************/}
            <Route exact path="/sync">
              <SyncPage />
            </Route>


            {/********************************* DASHBOARD PAGE   *********************/}
            <Route exact path="/">
                <Dashboard />
            </Route>



            {/********************************* ACCOUNTS PAGE   *********************/}
            {/* <Route exact path="/accounts">
                <Accounts />
            </Route>
            <Route path="/account/:id">
              <Account />
            </Route> */}

            {/********************************* REVIEWS PAGE   *********************/}
            <Route exact path="/reviews">
                <Reviews />
            </Route>

            {/********************************* LOCATIONS PAGE   *********************/}
            <Route exact path="/locations">
                <Locations />
            </Route>
            <Route path="/location/:id">
              <Location />
            </Route>
            { newUser && <Redirect to="/sync" /> }
          </Switch>
        </Container>
      </div>
    </AdminContext.Provider>
  )
}

export default AdminApp
