/****************************************************************
* @description: A Hook to manage syncing the locations data from
*               gmb to the database
****************************************************************/
import { useState, useEffect } from 'react'
import { ADD_LOCATIONS, UPDATE_LOCATIONS } from '../../queries/locations'
import useMutationBatching from '../useMutationBatching'


/****************************************************************
* Create an object that defines how a new location will be created
* in the database
****************************************************************/
const create_new_location = (location, accountId, userId) => {
  let { name, locationName, primaryPhone, websiteUrl, averageRating, totalReviewCount} = location
  let { displayName } = location.primaryCategory
  let locality, postalCode, addressLines, administrativeArea
  if(location.address) {
    ({ locality, postalCode, addressLines, administrativeArea } = location.address)
    addressLines = addressLines.toString()
  } else {
    locality = 'Location not registered.'
    postalCode = 'Postal Code not registered.'
    addressLines = 'Address Line not registered.'
    administrativeArea = 'Zip Code Not registered.'
  }
  let timestamp = new Date()
  timestamp = timestamp.toDateString()
  return{
    data: {
      userId,
      locationId: name,
      locationName: locationName ,
      primaryPhone: primaryPhone ? primaryPhone : 'None',
      displayName: displayName ,
      account: { connect: { id: accountId } }, 
      websiteUrl: websiteUrl ? websiteUrl : 'None',
      locality: locality ,
      postalCode: postalCode,
      administrativeArea: administrativeArea,
      addressLines: addressLines,
      timestamp: timestamp,
      averageRating: averageRating,
      totalReviewCount: totalReviewCount
    }
  }
}


/****************************************************************
* Create an object where the values are the values that will be
* updated at the specified location id
****************************************************************/
const update_location_info = (location, values) => {
  return {
    id: location.id,
    data: {
      ...values
    }
  }
}

const useLocationsSync = ({ dbLocations, gmbLocations, accountIds, reviewsMeta, userId}) => {
  const { setQuery, queryData: addedLocations } = useMutationBatching({
    mutation: ADD_LOCATIONS,
    createInputName: "locations",
    batch_size: 100
  })

  const { setQuery: setUpdateQuery } = useMutationBatching({
    mutation: UPDATE_LOCATIONS,
    createInputName: "locations",
    batch_size: 100
  })
  
  const [locationIds, setLocationIds ] = useState(null)
  const [sync, setSyncLocations ]      = useState(false)

  const syncLocations = () => {
    let existingLocations = Object.assign({}, ...dbLocations.data.allLocations.map( ({averageRating, totalReviewCount, locationId, id}) => ({[locationId]: { id, averageRating, totalReviewCount }})))
    let locations_to_add = []
    let locations_to_update = []
    gmbLocations.map( location => {
      let { name } = location
      location["averageRating"] = reviewsMeta[name].averageRating
      location["totalReviewCount"] = reviewsMeta[name].totalReviewCount
      let existingLocation = existingLocations[name]
      if (!existingLocation) {
        locations_to_add.push(create_new_location(location, accountIds[location.account], userId))
      } else {
        let {averageRating: curRating, totalReviewCount: curCount} = existingLocation
        let {averageRating, totalReviewCount } = location
        if(averageRating !== curRating || totalReviewCount !== curCount) {
          locations_to_update.push(update_location_info(existingLocation, { averageRating, totalReviewCount }))
        }
      }
    })
    setQuery(locations_to_add)
    setUpdateQuery(locations_to_update)
  }

  /****************************************************************
  * Query a sync update if state changes then wait for locations
  * from database and googlemybusiness to be present
  ****************************************************************/
  useEffect( () => {
    if(dbLocations && dbLocations.data && gmbLocations && sync && reviewsMeta && accountIds) {
      setSyncLocations(false)
      syncLocations()
    }
  },[dbLocations, gmbLocations, sync, reviewsMeta, accountIds])

  /****************************************************************
  * Create an object of location ids linking to the locations names
  * ex.) { accounts/129412481290/locations/sfsfsffs: locations.id }
  ****************************************************************/
 useEffect( () => {
    if (dbLocations.data && dbLocations.data.allLocations.length !== 0) {
      setLocationIds(Object.assign({}, ...dbLocations.data.allLocations.map(location => ({[location.locationId]: location.id}) )))
    }
  }, [dbLocations.data])

  return { addedLocations, setSyncLocations, locationIds } 
}

export default useLocationsSync