import { useState, useEffect } from 'react'
import useAccountsSync from './useAccountsSync'
import useLocationsSync from './useLocationsSync'
import useReviewsSync from './useReviewsSync'
import { getBlockStringIndentation } from 'graphql/language/blockString'

const useSynchronizer = ({db, gmb, userId, newSignup, setNewUser}) => {
  const { addedAccountData, accountIds } = useAccountsSync({
    dbAccounts: db.accounts,
    gmbAccounts: gmb.accounts
  })

  const [ itemBeingSynced, setItem ] = useState('Accounts')

  const { addedLocations, setSyncLocations, locationIds } = useLocationsSync({
    dbLocations: db.locations,
    gmbLocations: gmb.locations,
    accountIds: accountIds,
    reviewsMeta: gmb.reviewsMeta,
    userId
  })

  const { setSyncReviews, reviewsData, reviewsLoading } = useReviewsSync({
    dbReviews: db.reviews,
    gmbReviews: gmb.reviews,
    locationIds: locationIds,
    userId
  })

  /****************************************************************
   * Check if account data was added and the mutation queries have finished 
  ****************************************************************/
  useEffect( () => {  
    if(addedAccountData.data && !addedAccountData.loading) {
      setItem('Locations')
      setSyncLocations(true)
    }
  }, [addedAccountData.data, addedAccountData.loading])


  /****************************************************************
   * Check if locations data was added and the mutation queries have finished 
  ****************************************************************/
  useEffect( () => {  
    if(addedLocations) {
      setItem('Reviews')
      setSyncReviews(true)
    }
  }, [addedLocations])

  useEffect( () => {
    if(reviewsData) {
      setItem('Done')
      setNewUser(false)
    }
  }, [reviewsData])

  return { setSyncLocations, setSyncReviews, itemBeingSynced, reviewsLoading }
}

export default useSynchronizer