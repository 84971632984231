import React, { useEffect, useState } from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";
import { get_dates_between_two_dates } from '../hooks/DateParser'


const BarGraphWithLabels =({data, labels, max}) => {
  const [ dataset, setDataset] = useState(max ? data.sort((a,b) => b - a).slice(0, max) : data)

  let dataset_options = {
      backgroundColor: 'rgb(30, 155, 233)',
      borderColor: 'rgb(30, 155, 233)',
      borderWidth: 1
  }

  let options = {
    response: true,
    legend: { display: false },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: 'rgb(30, 155, 233)'
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'rgb(30, 155, 233)'
        }
      }]
    }

  }

  /********* Dynamic databar *********/
  useEffect( () => {
    setDataset(max ? data.sort((a,b) => b - a).slice(0, max) : data)
  }, [data])


  return( 
    <MDBContainer>
      <Bar 
        data={{
          labels: labels,
          datasets: [{
            data: dataset,
            ...dataset_options
          }]
        }} 
        options={options} 
      />
    </MDBContainer>
  )
}

const BarGraph = ({data, startDate, endDate}) => {
  const [ labels, setLabels ] = useState(get_dates_between_two_dates(startDate, endDate).labels)

  useEffect( () => {
    setLabels(get_dates_between_two_dates(startDate, endDate).labels)
  }, [startDate, endDate])

  let dataBar = {
    labels: labels,
    datasets: [{
      data: data,
      backgroundColor: 'rgb(30, 155, 233)',
      borderColor: 'rgb(30, 155, 233)',
      borderWidth: 1
    }],
  }

  let options = {
    response: true,
    legend: { display: false },
    scales: {
      xAxes: [{
        ticks: {
          fontColor: 'rgb(30, 155, 233)'
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'rgb(30, 155, 233)'
        }
      }]
    }

  }
  return(
    <MDBContainer>
      <Bar data={dataBar} options={options} />
    </MDBContainer>
  )
}

const ScoreGraph = ({scores, start_date_, end_date}) => {
  const [dataline, setDataline ] = useState({
    labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    datasets: [
      {
        label: "Current Week",
        borderColor: "rgb(205, 130, 158)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgb(205, 130,1 58)",
        pointBackgroundColor: "rgb(255, 255, 255)",
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(0, 0, 0)",
        pointHoverBorderColor: "rgba(220, 220, 220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        lineTension: 0.3,
        data: [...scores]
      }
    ]
  })

  useEffect( () => {
    setDataline({
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        datasets: [
          {
            label: "Current Week",
            borderColor: "rgb(205, 130, 158)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgb(205, 130,1 58)",
            pointBackgroundColor: "rgb(255, 255, 255)",
            pointBorderWidth: 10,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgb(0, 0, 0)",
            pointHoverBorderColor: "rgba(220, 220, 220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            lineTension: 0.3,
            data: [...scores]
          }
        ]
    })
  }, [scores])
  return(
    <MDBContainer>
      <h3 className="mt-5">Line chart</h3>
      <Line data={dataline} options={{ 
        responsive: true,
        legend: { display: false },

        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
              ticks: {
                max: Math.max(...scores) + 1,
                beginAtZero: true,
              },
              gridlines: {
                display: false
              }
            }
          ]}
        }} />
    </MDBContainer>
  )
}

export { 
  BarGraph,
  ScoreGraph, 
  BarGraphWithLabels,
}
