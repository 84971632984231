import  { gql } from 'apollo-boost'

const ALL_ACCOUNTS = gql`
{
  allAccounts {
    id,
    name,
    accountId,
    profilePhotoUrl,
  }
}
`

const ADD_ACCOUNT = gql`
  mutation CreateAccount($name: String!, $accountId: String!, $photoUrl: String!) {
    createAccount(
    data: {
      name: $name,
      accountId: $accountId,
      profilePhotoUrl: $photoUrl,
    }
    ) {
      id
    }
  }
`

const GET_ACCOUNT = gql`
  query GetAccount($id: ID!) {
    Account(where: { id: $id}) {
      id,
      name,
      accountId,
      profilePhotoUrl
    }
  }
`

export  {
  ALL_ACCOUNTS,
  ADD_ACCOUNT,
  GET_ACCOUNT 
}
