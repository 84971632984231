import React, { useEffect, useState } from "react"
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'

let client_id =  "689044191525-cmgp68sd7u4vpooofjm1nfrc0u7f5bq2.apps.googleusercontent.com"
//client_id = "121288523760-c67t50rn5fl6e9rq8fqoeilkk51dnnmj.apps.googleusercontent.com"

const EXISTING_USER = gql`
    query AllUsers($id: String!) {
        allUsers(where: {userId: $id}) {
            firstName
        }
    }
`

const HomePage = ({script, dispatch}) => {  
    const [googleToken, setGoogleToken]   = useState(null)
    const [getUser, { data }]             = useLazyQuery(EXISTING_USER);

    script.onload = () => {
        window.gapi.load('auth2', () => {  
            window.gapi.auth2.init({
                "client_id": client_id,
                scope: "https://www.googleapis.com/auth/business.manage"
            }).then( () => {
                window.gapi.signin2.render('g-signin2', {
                    'scope': 'profile email',
                    'width': 300,
                    'height': 55,
                    'longtitle': true,
                    'theme': 'dark',
                    'onsuccess': googleUser => onSignIn(googleUser),
                    'onfailure': () => {}
                });
            })
        })
    }

    const onSignIn = (googleUser) => {
        if (googleToken)
            return

        let id_token = googleUser.getAuthResponse().id_token;
        var myHeaders = new Headers();
        myHeaders.append('content-type', 'application/json');
        fetch("/google-auth", {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
                token: id_token,
                audience: client_id
            })
        })
        .then ( res => res.json())
        .then( payload => {
            getUser({variables: {id: payload.userid}})
            setGoogleToken({
                payload: payload,
                tokenInfo: googleUser.getAuthResponse(true) 
            })
            Cookies.set('session', JSON.stringify({
                token:  payload,
                tokenInfo: googleUser.getAuthResponse(true) 
            }))
        }).catch( err => {
            console.log("Error: ", err)
        })
    }

    useEffect( () => {
        //user does not exist. Send to sign up page
      if ( data && googleToken ) {
        let redirect = data.allUsers.length === 0 ? 'new' : 'registered'
        dispatch({
          type: 'setAuthentication',
          token:  googleToken.payload,
          tokenInfo: googleToken.tokenInfo,
          redirect: redirect,
          newSignup: redirect === "new" ? true : false
        })
        Cookies.set('session', JSON.stringify({
          token:  googleToken.payload,
          tokenInfo: googleToken.tokenInfo,
        })) 

      }
    }, [data, dispatch, googleToken])



    return (
        <div className="wcn-homepage">
            <div className="wcn-homepage__content">
                <h2 className="wcn-homepage__header"> West County Net  <br/> Google My Business App</h2>
                <div id="g-signin2" > </div> 
            </div>
        </div>
    )

}

export default HomePage
