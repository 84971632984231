import React,  { useState, useEffect } from "react"
import Cookies from 'js-cookie'
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    Typography
} from '@material-ui/core'
import { Link, Redirect } from 'react-router-dom'


const GoogleAccountModal = ({dispatch, accountInfo, anchor, setAnchor, displayModal, setDisplayModal, setSignedOut}) => {
  const onLogout = () => {
    //The global below IS NOT A COMMNET . Do not delete
    /* global gapi */
    Cookies.remove('session')
    if(gapi.auth2) {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            setSignedOut(true)
            auth2.disconnect();
        })
    } else {
        setSignedOut(true)
    }
  }

    return(
        <Menu 
            anchorEl={anchor}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id="wcn-header__bar"
            keepMounted
            open={displayModal}
            onClose={ () => { setAnchor(null);setDisplayModal(false) } }
        >
            <MenuItem onClick={onLogout}>
                <Link to="/">
                    Sign Out 
                </Link>
            </MenuItem>
        </Menu>
    )
    
}
const PublicGoogleHeader = ({dispatch, token}) => {
    const [signedOut, setSignedOut] = useState(false)

    const [ displayModal, setDisplayModal ] = useState(false)
    const [ anchor, setAnchor ] = useState(null)
    

    useEffect( () => {
        if ( signedOut )  {
            dispatch({
                type: 'resetLogin'
            })
            setSignedOut(false)
        }
    }, [signedOut])


    if (!token) {
        return <div>
        </div>
    }
    const { payload } = token

    return (
        <AppBar className="wcn-header" position="fixed">
            <Toolbar>
                {signedOut && <Redirect to="/" /> }
                <div className="logo"><img src="/assets/wcn-icon.png" /></div>
                <Typography variant="h6" noWrap>
                    Google Review Reporter
                </Typography>
                <div style={{flexGrow: "1"}} />
                <IconButton
                    edge="end"
                    aria-controls="wcn-header__bar"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    onClick={ (e) =>
                        {
                          setDisplayModal(!displayModal);
                          setAnchor(e.currentTarget)
                        }
                    }
                    color="inherit"
                >
                    <Avatar src={payload.picture} />
                    <div id="g-signin2" className="wcn-gsignin" > </div> 
                </IconButton>
                {displayModal && <GoogleAccountModal setSignedOut={setSignedOut} accountInfo={payload} dispatch={dispatch} anchor={anchor} setAnchor={setAnchor} displayModal={displayModal} setDisplayModal={setDisplayModal} /> }
            </Toolbar>
        </AppBar>
    )
}

export default PublicGoogleHeader
