import React from 'react'

const LoadingBar = ({isLoading}) => {
    return(
        <>
            {isLoading &&
                <div className="load-bar">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            }
        </>
    )
}
const Loader = ({isLoading}) => {
    return(
        <>
            {isLoading &&
                <div className="loader">Loading...</div>
            }
        </>
    )
}
export {
    LoadingBar,
    Loader
}