import React, { useState } from "react"
import { Redirect } from 'react-router-dom'
import { Formik, Form, Field  } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Cookies from 'js-cookie'

const ADD_USER = gql`
    mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $id: String!, $picture: String!) {
        createUser(
            data: {
                isAdmin: false,
                firstName: $firstName,
                lastName: $lastName,
                email:  $email,
                userId: $id,
                picture: $picture
              }
        ) {
            id
        }
    }
`

const ConfirmationModal = ({setRedirection}) => {
    return (
        <div className="wcn-register__modal__submitted" >
            <h2> Thank you for registering. </h2>
            <button onClick={() => setRedirection(true)} className="wcn-register-form__submit" type="button"> Go to Dashboard </button>
        </div>
    )
}

const SignUpModal = ({email, picture, family_name, given_name, onSubmit}) => {
    return (
        <Formik
            initialValues={{
                email: email,
                image: picture,
                lastName:  family_name,
                firstName: given_name,
            }}
            onSubmit={ (values) => onSubmit(values)}
        >   
            <Form className="wcn-form wcn-register__modal">
                <header id="wcn-form__register__header"> 
                    <h2 className="wcn-register__title"> Register an Account </h2>
                </header>

                <div className="wcn-user__image wcn-register__image"> 
                    <img alt="user" src={picture} />
                </div>
                <Field id="register_first" className="wcn-register-form__input" type="text" name="firstName"  placeholder="First Name" required />
                <Field id="register_last"  className="wcn-register-form__input" type="text" name="lastName"   placeholder="Last Name" required />
                <Field id="register_email" className="wcn-register-form__input" type="text" name="email"      placeholder="Email" required />
                <button className="wcn-register-form__submit" type="submit"> Sign Up </button>
            </Form>
        </Formik>
    )
}

const SignUpPage = ({LoginState, dispatch }) => {
    const [addUser, {error} ] = useMutation(ADD_USER)
    const [ hasRegistered, setRegistered ] = useState(false)
    const [onRedirectToDashBoard, setRedirection] = useState(false)
    
    /******************************  
     * Post to GraphQL Endpoint
    ********************************/
    const onSubmitRegistration = ({firstName, lastName, email, image}) => {
        addUser({
            variables: { 
                id: LoginState.token.userid,
                firstName: firstName,
                lastName: lastName,
                email: email,
                picture: image
            }
        })
        setRegistered(true)
        Cookies.set('session', JSON.stringify({
            token:  LoginState.token.payload,
            tokenInfo: LoginState.token.tokenInfo,
        })) 
        dispatch({
            type: "login"
        })
    }

    /******************************  REDIRECTION ROUTES *********************/
    if (!LoginState.token ) {
        return <Redirect to="/" />
    } else if (onRedirectToDashBoard) {
        return <Redirect to="/sync" />
    }
    Cookies.remove('session')

    /****************************** COMPONENTS *********************/
    return( 
        <div className="wcn-registerpage">
            {error && <div className="wcn-graphql__error"> There was an error with signing up </div>}
            { !hasRegistered && <SignUpModal {...LoginState.token.payload} onSubmit={onSubmitRegistration}/> }
            { hasRegistered && <ConfirmationModal setRedirection={setRedirection} /> }
        </div>
    )
}

export default SignUpPage