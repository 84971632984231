import axios from 'axios'
import { useState, useEffect} from 'react'


/*********************************************** DEFINED GOOGLE MY BUSINESS ENDPOINTS  ********************************/
let business_accounts_url  = 'https://mybusiness.googleapis.com/v4/accounts'
let base_url = 'https://mybusiness.googleapis.com/v4/' //will get /acconuts/id/location appended to it

const gmb_header = auth => ({
  headers: {
    Authorization: auth,
    'Content-Type': 'application/json'
  }
})



const useGmbAccounts = ({access_token, token_type}) => {
  const [accounts, setAccounts ] = useState(null)
  const [accountFetchErr, setErr] = useState(null)
  const [refetch, setRefetch ] = useState(false)

  const fetchData = () => {
    axios.get(business_accounts_url, {
      headers: {
        Authorization: token_type + ' ' + access_token,
        'Content-Type': 'application/json'
      }
    })
    .then( response => setAccounts(response.data.accounts))
    .catch( err =>  setErr(err.response.data.error))
  }

  useEffect( fetchData, [])

  useEffect( () => {
    accountFetchErr && console.log(accountFetchErr)
  }, [accountFetchErr])

  useEffect( () => {
    if ( refetch ) {
      fetchData()
      setRefetch(false)
    }
  }, [refetch])
  return { accounts, accountFetchErr, setRefetch }
}


const useGmbLocations = ({access_token, token_type})  => {
  const [locations, setLocations ] = useState(null)
  const [locationsFetchErr, setErr ] = useState(null)


  /****************************************************************
   * Fetch locations from a page token given back in the review response
  ****************************************************************/ 
  const getLocationsWithPageToken = (account, pageToken, auth, all_locations) => {
    return axios.get(base_url + account + '/locations?pageToken=pageSize=100&pageToken=' + pageToken, gmb_header(auth))
    .then( async res => {
      if (res.data.locations) {
        all_locations.push(...res.data.locations.map( location => ({account: account, ...location})))
      }
      if ( res.data.nextPageToken) {
        await getLocationsWithPageToken(account, res.data.nextPageToken, auth, all_locations)
      }
    })
  }

  const getLocations = async(accounts) => {
    const options = gmb_header(token_type + ' ' + access_token)
    try {
      const res = await axios.all(accounts.map( async({name}) => {
        const {data} = await axios.get(base_url + name + '/locations', options)
        let locations = data.locations ? data.locations.map( location => ({account: name, ...location})) : [] 
        if (data.nextPageToken) {
          await getLocationsWithPageToken(name, data.nextPageToken, token_type + ' ' + access_token, locations)
        }
        return locations
      }))
      setLocations(res.flat(1))
      return res.flat(1)
    } catch(err) {
      setErr(err)
    }
  }

  useEffect( () => {
    locationsFetchErr && console.log(locationsFetchErr)
  }, [locationsFetchErr])

  return { locations, getLocations }
}

const useGmb = ({tokenInfo}) => {
  const  { accounts                         } = useGmbAccounts(tokenInfo)
  const  { locations, getLocations          } = useGmbLocations(tokenInfo)
  const  { reviews, getReviews, reviewsMeta, fetchingGmbReviews} = useGmbReviews(tokenInfo)

  const [ refetchReviews, setRefetchReviews ] = useState(false)

  useEffect( () => {
    const fetcher = async() =>  {
      if(accounts ) {
        await getLocations(accounts)
      }
    }
    fetcher()
  }, [accounts])

  useEffect( () => {
    if(locations) {
      getReviews(locations)
    }
  }, [locations])

  useEffect( () => {
    if (refetchReviews && locations) {
      getReviews(locations)
      setRefetchReviews(false)
    }
  }, [refetchReviews, locations])

  const fetchNewReviews = async() => {
    if(!locations) {
      let locations = await getLocations(accounts)
    } 
    await getReviews(locations)
  }

  return { 
    accounts, 
    locations, 
    reviews, 
    reviewsMeta, 
    setRefetchReviews, 
    fetchingGmbReviews, 
    fetchNewReviews
  }
}

const useGmbReviews = ({access_token, token_type}) => {
  const [reviews, setReviews]       = useState(null)
  const [reviewsFetchErr, setErr]   = useState(null)
  const [reviewsMeta, setReviewMeta] = useState(null)
  const [fetchingGmbReviews, setFetching] = useState(false)

  /****************************************************************
   * Fetch reviews from a page token given back in the review response
  ****************************************************************/ 
  const getReviewsWithPageToken = (location, pageToken, auth, all_reviews) => {
    return axios.get(base_url + location + '/reviews?pageToken=pageSize=1400&pageToken=' + pageToken, gmb_header(auth))
    .then( async res => {
      if (res.data.reviews) {
        all_reviews.push(...res.data.reviews.map(review => ({...review, location: location})))
      }
      if ( res.data.nextPageToken) {
        await getReviewsWithPageToken(location, res.data.nextPageToken, auth, all_reviews)
      }
    })
  }

  const getReviews = async locations => {
    let reviewsMeta = {}
    setFetching(true)
    try {
      let res = await axios.all( locations.map( async({name}) => {
        let res =  await axios.get(base_url + name + '/reviews', gmb_header(token_type + ' ' + access_token))
        let location_reviews = res.data.reviews ? res.data.reviews.map( review => ({...review, location: name}) ) : []
        reviewsMeta[name] = {
          locationId: name, 
          totalReviewCount: res.data.totalReviewCount ? res.data.totalReviewCount : 0, 
          averageRating: res.data.averageRating ? res.data.averageRating : 0
        }
        if (res.data.nextPageToken) {
          await getReviewsWithPageToken(name, res.data.nextPageToken, token_type + ' ' + access_token, location_reviews)
        }
        return location_reviews
      }))
      setReviews(res.flat(2))
      setReviewMeta(reviewsMeta)
      setFetching(false)
    } catch (err) {
      setErr(err)
    }
  }

  /****************************************************************
   * Check for fetch error from GMB
  ****************************************************************/ 
  useEffect( () => {
    reviewsFetchErr && console.log(reviewsFetchErr)
  }, [reviewsFetchErr])

  return { reviews, reviewsFetchErr, getReviews, reviewsMeta, fetchingGmbReviews}
}

export default useGmb