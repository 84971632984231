import { ALL_LOCATION_IN_DATE_RANGE_REVIEWS } from '../queries/reviews'
import { useLazyQuery } from '@apollo/react-hooks'
import { useState, useEffect } from 'react'
import { review_count_in_range, get_review_count } from './DateParser'

const useLocationId = (id) => {
  let today = new Date()
  let last_week = (new Date()).setDate(today.getDate() - 7)

  const [locationRating, setRating] = useState('')
  const [reviewsCount, setCount]    = useState(0)
  const [previousReviewsCount, setPreviousCount] = useState(0)
  const [ratings, setRatings ]      = useState([])
  const [ratingCountPerDay, setRatingCountPerDay] = useState([])
  const [loadReviews, { data, loading: reviewsLoading, error }] = useLazyQuery(ALL_LOCATION_IN_DATE_RANGE_REVIEWS)

  const [startDate, setStartDate] = useState(last_week)
  const [endDate, setEndDate] = useState(today)

  useEffect( () => {
    loadReviews({ variables: { id: id } })
  }, [])

  const reviewsInRange = (startDate, endDate) => {
    loadReviews({
      variables: {
        id: id,
        //startDate: startDate,
        endDate: endDate
      }
    })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const reducer = (accumulator, review) => accumulator + review.starRating

  useEffect( () => {
    if(data && data.allReviews && !reviewsLoading) {
      let {num_reviews_before_start_date, review_count_between_dates } = get_review_count(startDate, endDate, data.allReviews)

      //setRatings(get_reviews_scores(last_week, today, data.allReviews))
      setCount(data.allReviews.length)
      setPreviousCount(review_count_in_range(new Date('1900'),startDate, data.allReviews))

      setRatingCountPerDay(() => {
        let cntr = num_reviews_before_start_date
        return review_count_between_dates.map( score => {
          cntr+= score
          return cntr
        })
      })

      

      let averageRating
      if (data.allReviews.length > 0) {
        averageRating = (data.allReviews.reduce(reducer, 0)  / data.allReviews.length).toFixed(1)
      } else {
        averageRating = 0
      }
      setRating(averageRating)
    }
  },[data, startDate, endDate])

  return { 
    locationRating, 
    reviewsCount, 
    previousReviewsCount, 
    data, 
    reviewsLoading, 
    reviewsInRange, 
    ratings,
    ratingCountPerDay
  }
}

export default useLocationId