import React, { useContext } from 'react'
import AdminContext from '../AdminContext'
import { LocationsTable } from './LocationsTable'

const Locations = () => {
  const { db } = useContext(AdminContext)
  const { locations } = db

  return(
    <div className="wcn-admin__page">
      <h2>All Locations</h2>
      <LocationsTable locations={locations} />
    </div>
  )
}

export { Locations }
