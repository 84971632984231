import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const Datepicker = ({onChangeDate}) => {
  let last_week = (new Date()).setDate((new Date()).getDate() - 7) //get last weeks date
  const [startDate, setStartDate] = useState(last_week)
  const [endDate, setEndDate]     = useState(new Date())

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline-start"
            label="Start Date"
            value={startDate}
            onChange={(v) => setStartDate(v)}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
          />
        </Grid>

        <Grid item> 
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline-end"
            label="End Date"
            value={endDate}
            onChange={(v) => setEndDate(v)}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
          />
        </Grid>

        <Grid item >
          <Button onClick={() => onChangeDate(startDate, endDate)} className="wcn-datepicker-btn__apply" variant="contained"> Apply </Button>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default Datepicker