import React, {useState, useEffect, useContext }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import AdminContext from '../AdminContext'
import { Container, Grid, Card, CardContent, Typography } from '@material-ui/core'
import { BarGraphWithLabels } from '../Components/Graphs'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import { GET_ALL_REVIEW_COUNT, GET_ALL_REVIEWS_LOCATION_BY_DATE } from '../queries/reviews'
import {Loader} from '../../Components/Loader'
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core'
import {Link} from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const TopLocationsByDate = ({max, date}) => {
  const { LoginState } = useContext(AdminContext)
  const {data, loading, error} = useQuery(GET_ALL_REVIEWS_LOCATION_BY_DATE, {
    variables: { date, userId: LoginState.token.userid }
  })

  const [ locationsCount, setLocationsCount ] = useState(false)


    useEffect(() => {
        if(data) {
            let results = data.allReviews.reduce((countMap, item) => {
                let id = item.location.id
                let locationName = item.location.locationName
                let city = item.location.locality
                countMap[id] = {'count': countMap[id] && ++countMap[id].count || 1, 'city': city, 'name': locationName}
                return countMap;
            }, [])
            setLocationsCount(results)
        }
    }, [data])

    if(loading)
    return (
        <Card>
            <Loader isLoading={loading} />
        </Card>
    ) 

    return (
        <Card className="wcn-location-card__line_graph">
            <CardContent>
                <Typography variant="subtitle1">Locations with Reviews in 7 Days</Typography>
                {locationsCount && 
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Location</TableCell>
                          <TableCell align="left">City</TableCell>
                          <TableCell align="right">Review Increase</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(locationsCount).map( ([id, info]) => {
                          return(
                            <TableRow key={id + 'row'}>
                              <TableCell > 
                                <Link to={"/location/" + id} >{info.name}</Link>
                              </TableCell>
                              <TableCell align="left">{info.city}</TableCell>
                              <TableCell align="right">{info.count} <ArrowUpwardIcon className="color-green" fontSize="inherit" /></TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                    </Table>
                  </TableContainer>
                }
            </CardContent>
        </Card>
    )
}


const TopLocationsOverall = ({locations}) => {
  const [topLocationsData, setData ] = useState([])
  const [topLocationsLabels, setLabels ] = useState([])

  useEffect( () => {
    let topLocations = locations.sort((a, b) => b.totalReviewCount - a.totalReviewCount).slice(0, 20)
    let data   = []
    let labels = []
    topLocations.map( ({locationName, totalReviewCount, locality}) => {
      data.push(totalReviewCount)
      labels.push(locationName + ' - ' + locality)
    })
    setData(data)
    setLabels(labels)
  }, [locations])

  return (
    <Card className="wcn-location-card__line_graph">
      <CardContent>
        <Typography variant="subtitle1">Top Locations Overall</Typography>
        <BarGraphWithLabels labels={topLocationsLabels} data={topLocationsData}/>
      </CardContent>
    </Card>
  )
}


const StarRatingBarGraph = () => {
  const { LoginState } = useContext(AdminContext)
  const {data, loading, error } = useQuery(GET_ALL_REVIEW_COUNT, {
    variables: {userId: LoginState.token.userid}
  })

  if(loading)
    return (
        <Card>
            <Loader/>
        </Card>
    ) 

  return (
    <Card className="wcn-location-card__line_graph">
      <CardContent> 
      <Typography variant="subtitle1">Overall Star Ratings</Typography>
        {
          data && 
          <BarGraphWithLabels 
            labels={["1", "2", "3", "4", "5"]}
            data={[data.StarRatings1.count, data.StarRatings2.count, data.StarRatings3.count, data.StarRatings4.count, data.StarRatings5.count]}
          />
        }
      </CardContent>
    </Card>
  )
}



const Dashboard = ({}) => {
  const { db, LoginState } = useContext(AdminContext)
  const { given_name, family_name } = LoginState.token.payload
  const { locations } = db
  return(
    <div className="wcn-admin__page">
      <h2> Welcome {given_name} { family_name }  </h2>
        <Container maxWidth={false}>
            <Grid container spacing={3}>
                <Grid item sm={8}>
                    {locations.data && <TopLocationsOverall locations={locations.data.allLocations} />}
                </Grid>
                <Grid item sm={4}>
                    <StarRatingBarGraph />
                </Grid>
                <Grid item sm={12}>
                    <TopLocationsByDate max={20} date={ (new Date( (new Date()).setDate( (new Date()).getDate() - 7))).toISOString()} />
                </Grid>
                <Grid item sm={6}>
                    
                </Grid>
            </Grid>
        </Container>      
    </div>
  )
}

export {Dashboard}
