/****************************************************************
* @description: A selection of queries for the GraphQL Reviews
*               endpoints
****************************************************************/
import  { gql } from 'apollo-boost'

const ALL_REVIEWS = gql`
{
  allReviews {
    id,
    reviewId,
    reviewerDisplayName,
    reviewerProfilePhotoUrl,
    comment,
    location { 
      id, 
      locationName,
      addressLines,
      administrativeArea,
      postalCode,
      locality,
      websiteUrl
    },
    createTime,
    updateTime,
    starRating
  }
}
`

const ALL_AUTH_REVIEWS = gql`
query AllAuthReviews($userId: String!) {
  allReviews(where: { userId: $userId}) {
    id,
    reviewId,
    reviewerDisplayName,
    reviewerProfilePhotoUrl,
    comment,
    location { 
      id, 
      locationName,
      addressLines,
      administrativeArea,
      postalCode,
      locality,
      websiteUrl
    },
    createTime,
    updateTime,
    starRating
  }
}
`

const ALL_LOCATION_REVIEWS = gql`
  query AllLocationReviews($id:  ID!) {
    allReviews(where: {location: {id: $id}}) {
      starRating,
      id,
      updateTime,
      createTime,
      comment,
      reviewerProfilePhotoUrl,
      reviewerDisplayName,
      reviewId,
      location { 
        id, 
        locationName,
        addressLines,
        administrativeArea,
        postalCode,
        locality,
        websiteUrl
      }
    }
  } 
`

const ADD_REVIEW = gql`
  mutation CreateReview($reviewId: String!,$reviewerDisplayName: String!,$reviewerProfilePhotoUrl: String!,$comment: String!,$location: ID!, $createTime: String!,$updateTime: String! ,$starRating: Int!) {
    createReview(
      data: {
        reviewId: $reviewId,
        reviewerDisplayName: $reviewerDisplayName,
        reviewerProfilePhotoUrl: $reviewerProfilePhotoUrl,
        comment: $comment,
        location: { connect: { id: $location} } ,
        createTime: $createTime,
        updateTime: $updateTime,
        starRating: $starRating
      }
    ){
      id
    }
  }
`

const ADD_REVIEWS = gql`
  mutation CreateReviews($reviews: [ReviewsCreateInput!]) {
    createReviews(data: $reviews ) {
      id
    }
  }
`

const ALL_LOCATION_IN_DATE_RANGE_REVIEWS = gql`
  query AllLocationReviews($id:  ID!,  $endDate: DateTime, $startDate: DateTime) {
    allReviews(where: {
        location: { id: $id} 
        createTime_gte: $startDate,
        createTime_lte: $endDate
      }
    ){
      starRating,
      id,
      updateTime,
      createTime,
      comment,
      reviewerProfilePhotoUrl,
      reviewerDisplayName,
      reviewId,
      location { 
        id, 
        locationName,
        addressLines,
        administrativeArea,
        postalCode,
        websiteUrl
        locality,
      },
    }
  } 
`

/*
const GET_REVIEW_COUNT = gql`
  query  GetReviewCount($starRating) {
    _allReviewsMeta(where: {starRating_in: $starRating}) {
      count
    }
  }
`*/

const GET_ALL_REVIEW_COUNT = gql`
  query GetAllLocationsStarRatings($userId: String!) {
    StarRatings1: _allReviewsMeta(where: {starRating_in: 1, userId: $userId}) { 
        count
    }
    StarRatings2: _allReviewsMeta(where: {starRating_in: 2, userId: $userId}) { 
        count
    }
    StarRatings3: _allReviewsMeta(where: {starRating_in: 3, userId: $userId}) { 
        count
    }
    StarRatings4: _allReviewsMeta(where: {starRating_in: 4, userId: $userId}) { 
        count
    }
    StarRatings5: _allReviewsMeta(where: {starRating_in: 5, userId: $userId}) { 
        count
    }
  }
`

const GET_ALL_REVIEWS_LOCATION_BY_DATE = gql`
  query GetAllReviewsLocationBydate($date: DateTime, $userId: String!) {
    allReviews(where: {createTime_gt: $date, userId: $userId})
    {
      location {
        id,
        locationName,
        locality
      },
    }
  }

`

export  {
  ADD_REVIEW,
  ADD_REVIEWS,
  ALL_REVIEWS,
  ALL_LOCATION_REVIEWS,
  ALL_LOCATION_IN_DATE_RANGE_REVIEWS,
  GET_ALL_REVIEW_COUNT,
  GET_ALL_REVIEWS_LOCATION_BY_DATE,
  ALL_AUTH_REVIEWS
}
