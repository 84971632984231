/****************************************************************
* @description: A selection of queries for the GraphQL Locations
*               endpoints
****************************************************************/
import  { gql } from 'apollo-boost'

const ALL_LOCATIONS = gql`
{
  allLocations {
    id,
    locationId,
    locationName,
    primaryPhone,
    displayName,
    account { id },
    websiteUrl,
    locality,
    postalCode,
    administrativeArea,
    addressLines,
    timestamp,
    averageRating,
    totalReviewCount
  }
}
`
const ALL_AUTH_LOCATIONS = gql`
  query AllAuthLocations($userId: String!) {
    allLocations(where: { userId: $userId}) {
      id,
      locationId,
      locationName,
      primaryPhone,
      displayName,
      account { id },
      websiteUrl,
      locality,
      postalCode,
      administrativeArea,
      addressLines,
      timestamp,
      averageRating,
      totalReviewCount
    }
  }
`
const ADD_LOCATION = gql`
  mutation CreateLocation($locationId: String!,$locationName: String!,$primaryPhone: String!,$displayName: String!,$account: ID!, $websiteUrl: String!,$locality: String! ,$postalCode: String!,$administrativeArea: String!,$addressLines: String!,$timestamp: String!) {
    createLocation(
      data: {
        locationId: $locationId,
        locationName: $locationName,
        primaryPhone: $primaryPhone,
        displayName: $displayName,
        account: { connect: { id: $account} } ,
        websiteUrl: $websiteUrl,
        locality: $locality,
        postalCode: $postalCode,
        administrativeArea: $administrativeArea,
        addressLines: $addressLines,
        timestamp: $timestamp
      }
    ){
      id
    }
  }
`

const GET_LOCATION = gql`
  query GetLocation($id: ID!) {
    Location(where: { id: $id}) {
      id,
      locationId,
      locationName,
      primaryPhone,
      displayName,
      account { id },
      websiteUrl,
      locality,
      postalCode,
      administrativeArea,
      addressLines,
      timestamp,
      averageRating,
      totalReviewCount
    }
  }
`

const ALL_ACCOUNT_LOCATIONS = gql`
  query AllAccountLocations($id: ID!) {
    allLocations(where: { account: { id: $id}}) {
      id,
      locationId,
      locationName,
      primaryPhone,
      displayName,
      account { id },
      websiteUrl,
      locality,
      postalCode,
      administrativeArea,
      addressLines,
      timestamp,
      averageRating,
      totalReviewCount
    }
  }
`

const UPDATE_LOCATION = gql`
mutation UpdateLocation($id: ID!, $totalReviewCount: Int!, $averageRating: Float!)  {
  updateLocation(locationId: $id, data: {
    averageRating: $averageRating,
    totalReviewCount: $totalReviewCount
  }){
    id
  }
}
`
const ADD_LOCATIONS = gql`
  mutation CreateLocations($locations: [LocationsCreateInput!]) {
    createLocations(data: $locations ) {
      id
    }
  }
`

const UPDATE_LOCATIONS = gql`
  mutation UpdateLocations($locations: [LocationsUpdateInput!]) {
    updateLocations(data: $locations) {
      id
    }
  }
`

export  {
  GET_LOCATION,
  ADD_LOCATION,
  ADD_LOCATIONS,
  ALL_LOCATIONS,
  UPDATE_LOCATION,
  UPDATE_LOCATIONS,
  ALL_ACCOUNT_LOCATIONS,
  ALL_AUTH_LOCATIONS
}
