import React, {useState, useContext, useEffect }  from "react"
import { Redirect } from "react-router-dom"
import AdminContext from '../AdminContext'
import LinearProgress from '@material-ui/core/LinearProgress';
const SyncPage = () => {
    const { resync } = useContext(AdminContext)
    const { itemBeingSynced } = resync
    const [ value, setValue ] = useState(0)


    useEffect( () => {
        if(itemBeingSynced === "Accounts")
            setValue(25)
        if(itemBeingSynced === "Locations")
            setValue(50)
        if(itemBeingSynced === "Reviews")
            setValue(75)
        if(itemBeingSynced === "Done")
            setValue(100)
    }, [itemBeingSynced])

    if ( resync.initFinished)  {
        return(
            <Redirect to="/" />
        )
    }

    return(
        <div>
            <h2>Syncing the GMB Data. </h2>
            {itemBeingSynced !== "Done" && <span> Currently Syncing </span>}
            <span>{itemBeingSynced}</span>
            <LinearProgress
                variant="determinate"
                color="secondary"
                value={value}
            />
        </div>
    )
}

export default SyncPage