import { useState, useEffect } from 'react'
import { ADD_REVIEWS   } from '../../queries/reviews'
import useMutationBatching from '../useMutationBatching'


const rating_mapping = {"ONE":1, "TWO":2, "THREE":3, "FOUR":4, "FIVE":5}

const useReviewsSync = ({dbReviews, gmbReviews, locationIds, userId}) => {
  const { setQuery, queryData: reviewsData, queryLoading: reviewsLoading } = useMutationBatching({
    mutation: ADD_REVIEWS,
    createInputName: "reviews",
    batch_size: 100
  })

  const [sync, setSyncReviews] = useState(false)

  const syncReviews = () => {
    let existingReviews = dbReviews.data.allReviews.map( ({reviewId}) => reviewId)
    let queryReviews = []
    gmbReviews.map( ({name, reviewer, comment, createTime, updateTime, starRating, location}) => {
      if (!existingReviews.includes(name)) {
        let { displayName, profilePhotoUrl } = reviewer
        queryReviews.push({
            data: {
              userId,
              comment: comment ? comment : '',
              reviewId: name,
              location: { connect: { id: locationIds[location]} },
              createTime: createTime,
              updateTime: updateTime,
              starRating: rating_mapping[starRating],
              reviewerDisplayName: displayName,
              reviewerProfilePhotoUrl: profilePhotoUrl
            }
        })
      }
    })
    setQuery(queryReviews)
  }

  /****************************************************************
  * Query a sync update if state changes then wait for reviews
  * from database and googlemybusiness to be present
  ****************************************************************/
  useEffect( () => {
    if(dbReviews && dbReviews.data && !dbReviews.loading && gmbReviews && sync && locationIds) {
      setSyncReviews(false)
      syncReviews()
    }
  },[dbReviews, gmbReviews, sync, locationIds])

  return { setSyncReviews, reviewsData, reviewsLoading }
}

export default useReviewsSync
