import React from 'react'
import { Rating } from '@material-ui/lab'
import { Avatar, Card, CardContent, Tooltip } from '@material-ui/core'
import {Link} from 'react-router-dom'
import moment from 'moment'

const ReviewCard = ({createTime, comment, starRating, reviewerDisplayName, reviewerProfilePhotoUrl, location}) => {
    const oneWeek = moment().subtract(7,'d').format('YYYYMMDD');
    const oneWeekReadable = moment(oneWeek).format('MMMM DD, YYYY')
    return(
        <Card className={"wcn-card__reviewer__wrapper wcn-card__reviewer__wrapper--rating-" + starRating}>
            <CardContent>
                <div className="wcn-review-card__header">
                    <div className="wcn-review-card__reviewer_info">
                        <Avatar alt={reviewerDisplayName} src={reviewerProfilePhotoUrl}/>
                        <h3 className="wcn-review-card__name">{ reviewerDisplayName }</h3>
                    </div>
                    <div className="wcn-review-card__rating">
                        { moment(createTime).format('YYYYMMDD') >= oneWeek &&
                            <Tooltip
                                title={"New reviews since " + oneWeekReadable}
                                placement="top"
                            >
                                <span className="wcn-review-new">New</span>
                            </Tooltip>
                        }
                        <Rating name="location-rating" value={starRating} precision={0.1} readOnly={true}/> 
                        <span className="rating-value">{starRating}</span>
                    </div>
                </div>
                <div className="wcn-review-card__comment">
                    <p>{comment}</p>
                </div>
                <div className="wcn-review__footer">
                    <span className="wcn-review__location">Review for <Link to={"/location/" + location.id} > {location.locationName} </Link></span>
                    <span className="wcn-review__date">Posted on {moment(createTime).format('MMM Do YYYY') + " @ " + moment(createTime).format('h:mm a') }</span>
                </div>
            </CardContent>
        </Card>
    )
}

export default ReviewCard 