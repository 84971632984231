/**************************************************************
 * Sort functions for sorting the tables
 * All functions found in: 
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 ***************************/

const numberAscSort = (a,b) => a - b

const numberDescSort = (a,b) => b - a

const stringDescSort = (a,b) => {
  if (a > b) {
    return -1;
  }
  if (a < b) {
    return 1;
  }
  return 0;
}

const stringAscSort = (a,b) => {
  if (a > b) {
    return 1;
  }
  if (a < b) {
    return -1;
  }
  return 0;
}

export {
  numberAscSort,
  stringAscSort,
  numberDescSort,
  stringDescSort,
}