import React, {useEffect, useState }  from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GET_LOCATION } from '../../queries/locations'
import { useParams } from 'react-router-dom'
import useLocationId from '../../hooks/useLocationId'
import { Grid, Card, CardContent, Toolbar, Typography, Box, Tooltip } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import ReviewsTable from '../../Reviews/Components/ReviewTable'
import {LoadingBar} from '../../../Components/Loader'
import Datepicker from '../../Components/DatePicker'
import { BarGraph } from '../../Components/Graphs'
import { faArrowUp, faArrowsAltH }       from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon }      from '@fortawesome/react-fontawesome'
import LaunchIcon from '@material-ui/icons/Launch';

/****************************************************************
* Component to render the number of reviews and its increase
****************************************************************/
const ReviewsCountCard = ({reviewsCount, previousReviewsCount}) => {
  let review_increase = reviewsCount - previousReviewsCount
  return(
    <Card className="location-review-count">
      <CardContent>
        <h3> {reviewsCount} Reviews </h3>
        <Tooltip
            placement="right"
            title="Previous Period Comparison"
        >
            <span className="review-card__count"> 
                {review_increase > 0 && <FontAwesomeIcon icon={faArrowUp} className="wcn-review__count_icon wcn-review__increase_icon"/>}
                {review_increase === 0 && <FontAwesomeIcon icon={faArrowsAltH} className="wcn-review__count_icon wcn-review__no_increase_icon" />}
                {review_increase}
            </span>
        </Tooltip>
      </CardContent>
    </Card>
  )
}

/****************************************************************
* Component to render stars that reprsent a rating
****************************************************************/
const RatingCard = ({rating}) => {
  return(
    <Card className="location-review-rating">
      <CardContent>
        <Rating name="location-rating" value={parseInt(rating)} precision={0.1} readOnly={true}/> 
        <span className="review-card__rating"> { rating } </span>
      </CardContent>
    </Card>
  )
}

/****************************************************************
* Component to render a bar graph of the ratings
****************************************************************/
const BarGraphCard = ({data, startDate, endDate}) => {
  return(
    <Card className="wcn-location-card__line_graph">
      <CardContent>
        <BarGraph data={data} startDate={startDate} endDate={endDate}/>
      </CardContent>
    </Card>
  )
}

const Location = () => {
  let { id } = useParams()
  const { locationRating, reviewsCount, data: reviewsData, reviewsLoading, reviewsInRange, previousReviewsCount, ratingCountPerDay } = useLocationId(id)
  const { data , loading: locationLoading } = useQuery(GET_LOCATION, {
    variables: {
        id: id
    }
  })
  const [isLoading, setLoading] = useState(true)
  let last_week = (new Date()).setDate((new Date()).getDate() - 7) //get last weeks date
  const [startDate, setStartDate] = useState(last_week)
  const [endDate, setEndDate]     = useState(new Date())

  useEffect( () => {
    if(!reviewsLoading && !locationLoading) {
      setLoading(false)
    } else {
      setLoading(true)
    }

  }, [reviewsLoading, locationLoading])

  const onChangeDate = (startDate, endDate) => {
    reviewsInRange(startDate, endDate)
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return(
    <div className="wcn-admin__page wcn-page__location">
      <LoadingBar isLoading={isLoading} />
        <Toolbar className="wcn-page__header">
          {data && 
            <div className="wcn-location__info">
              <Typography variant="h6"> { data.Location.locationName }</Typography>
              <a href={data.Location.websiteUrl} target="_blank" rel="noopener">Website <LaunchIcon fontSize="inherit" /></a>
            </div>
          }
          <Box className="wcn-datepicker">
            <Datepicker onChangeDate={onChangeDate} />
          </Box>
        </Toolbar>
        <Grid container spacing={4} justify="center" className="wcn-location__statstics">
          <Grid xs={3} item>
            <ReviewsCountCard reviewsCount={reviewsCount} previousReviewsCount={previousReviewsCount}/>
            <RatingCard rating={locationRating} />
          </Grid>
          <Grid xs={9} item>
            <BarGraphCard  data={ratingCountPerDay} startDate={startDate} endDate={endDate} />
          </Grid>
        </Grid>
        {reviewsData &&
            <ReviewsTable reviews={reviewsData} />
        }
    </div>
  )
}

export { Location }