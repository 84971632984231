/************************************************************
 * This file contains different functions that help manage
 * the dates that are given back 
************************************************************/
const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]


const review_count_in_range = (start_date, end_date, reviews) => reviews.filter(review => new Date(review.createTime) >= start_date && new Date(review.createTime) <= end_date).length

const get_review_count = (start_date, end_date, reviews)  => {
  let filtered_scores = get_dates_between_two_dates(start_date, end_date).label_mapping
  let num_reviews_before_start_date = 0

  /***************** Store the number of reviews per day ************/
  reviews.map( ({createTime, }) => {
    let createDate = new Date(createTime)
    if ( createDate >= start_date && createDate <= end_date ) {
      let parsedDate = createTime.split("T")[0]
      filtered_scores[weekday[createDate.getUTCDay()] + ' ' + parsedDate]++
    } else {
      num_reviews_before_start_date++
    }
  })

  return {
    num_reviews_before_start_date: num_reviews_before_start_date,
    review_count_between_dates: Object.values(filtered_scores).map(score => score)
  }
}



const get_dates_between_two_dates = (start_date, end_date) => {

  let label
  let labels        = []
  let label_mapping = {}

  let day = new Date(start_date)
  let end_day_string = new Date(end_date).toISOString().split("T")[0]
  while(day.toISOString().split("T")[0] != end_day_string) {

    /// Store date
    label = weekday[day.getUTCDay()] + ' ' + day.toISOString().split("T")[0]
    label_mapping[label] = 0
    labels.push(label)

    //move to next day
    day = day.setDate(day.getDate() + 1)
    day = new Date(day)
  }

  return {
      labels: labels,
      label_mapping: label_mapping
  }

}

export {
  review_count_in_range,
  get_review_count,
  get_dates_between_two_dates
}