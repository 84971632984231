import React, { useContext } from 'react'
import AdminContext from '../AdminContext'
import ReviewsTable from './Components/ReviewTable'


const Reviews = () => {
  const { db } = useContext(AdminContext)
  const { reviews } = db

  return(
    <div className="wcn-admin__page">
      <h2>Reviews</h2>
      <div className="wcn-page__content wcn-page__reviews">
        <ReviewsTable reviews={reviews.data} />
      </div>
    </div>
  )
}

export { Reviews }
