import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import AdminContext from '../AdminContext'
import { 
  Button,
  Paper, 
  Table, 
  TableRow, 
  TableBody, 
  TableHead, 
  TableCell, 
  TableContainer, 
  TableSortLabel,
  TablePagination,
  TableFooter,
  Toolbar,
  Typography

} from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {
  numberAscSort,
  stringAscSort,
  numberDescSort,
  stringDescSort,
} from '../utils/sortFunctions'

import { createRow, saveCSV} from '../utils/csvFunctions'


const onExportCSV = (fileName, locations) => {
  // Init the table row
  let row_header = ['Location Name', 'Review Count', 'Average Rating', 'Address', 'City', 'State', 'Zip', 'Website', 'Phone Number']
  let row
  let csvContent = createRow(row_header)

  locations.map( ({
    locality,
    postalCode,
    primaryPhone,
    locationName,
    websiteUrl,
    addressLines,
    averageRating,
    totalReviewCount,
    administrativeArea,
  }) => {

    // Create a table row with the order matching the header
    addressLines = addressLines.replace('#','Suite')
    row = [locationName, totalReviewCount, averageRating, addressLines, locality, administrativeArea, postalCode, websiteUrl, primaryPhone]
    row = row.map(el => '"' + el + '"')
    csvContent += createRow(row)
  })

  saveCSV(fileName, csvContent)
}

const LocationRow = ({id, averageRating, totalReviewCount, locationName, administrativeArea, locality }) => {
  return(
    <TableRow key={id + 'row'}>
      <TableCell > 
        <Link to={"/location/" + id} >{locationName}</Link>
      </TableCell>
      <TableCell align="left">{locality}</TableCell>
      <TableCell align="left">{administrativeArea}</TableCell>
      <TableCell align="right"><span className="location-review-count">{totalReviewCount}</span></TableCell>
      <TableCell align="right"><span className={"location-rating rating-"+Math.floor(averageRating)}>{averageRating}</span></TableCell>
    </TableRow>
  )
}

const TableHeaderCell = ({align, text, onClick, isActive, direction }) => {
  return (
    <TableCell align={align ? align : 'inherit'}> 
      <TableSortLabel
        active={isActive}
        direction={direction}
        onClick={onClick}
      >
        {text} 
      </TableSortLabel> 
    </TableCell>
  )
}
  
const LocationsTable = ({locations: locs}) => {
  const [locations, setLocations ] = useState(locs.data ? locs.data.allLocations: [])
  const [ rowsPerPage, setRowsPerPage ] = useState(25)
  const [ page, setPage ] = useState(0)
  const { resync } = useContext(AdminContext)

  useEffect(() => {
    if(locs.data && locs.data.allLocations)
      setLocations(locs.data.allLocations)
  }, [locs])

  const [ activeSortLabel, setActiveSort ] = useState('')
  const [sortOrder, setSortOder ] = useState('desc')
  const [sortFunction, setSortFunction ]  = useState(null)


  const onSortRating = () => {
    setActiveSort('rating')
    if (sortOrder === 'asc') {
      setLocations(locations.sort((a,b) => numberDescSort(a.averageRating, b.averageRating)))
      setSortOder('desc')
    } else {
      setLocations(locations.sort((a,b) => numberAscSort(a.averageRating, b.averageRating)))
      setSortOder('asc')
    }
  }

  const onSortReview = () => {
    setActiveSort('review_count')
    if (sortOrder === 'asc') {
      setLocations(locations.sort((a,b) => numberDescSort(a.totalReviewCount, b.totalReviewCount)))
      setSortOder('desc')
    } else {
      setLocations(locations.sort((a,b) => numberAscSort(a.totalReviewCount, b.totalReviewCount)))
      setSortOder('asc')
    }
  }

  const onSortName = () => {
    setActiveSort('name')
    if (sortOrder === 'asc') {
      setLocations(locations.sort((a,b) => stringDescSort(a.locationName, b.locationName)))
      setSortOder('desc')
    } else {
      setLocations(locations.sort((a,b) => stringAscSort(a.locationName, b.locationName)))
      setSortOder('asc')
    }
  }

  const onSortCity = () => {
    setActiveSort('city')
    if (sortOrder === 'asc') {
      setLocations(locations.sort((a,b) => stringDescSort(a.locality, b.locality)))
      setSortOder('desc')
    } else {
      setLocations(locations.sort((a,b) => stringAscSort(a.locality, b.locality)))
      setSortOder('asc')
    }
  }

  const onSortState = () => {
    setActiveSort('state')
    if (sortOrder === 'asc') {
      setLocations(locations.sort((a,b) => stringDescSort(a.administrativeArea, b.administrativeArea)))
      setSortOder('desc')
    } else {
      setLocations(locations.sort((a,b) => stringAscSort(a.administrativeArea, b.administrativeArea)))
      setSortOder('asc')
    }
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }


  return(
    <Paper className="wcn-table__wrapper" elevation={3}>
      <Toolbar className="table-header">
        <Typography color="inherit" variant="subtitle1">
          Locations
        </Typography>
        <Toolbar>
            <Button 
                className="button-download"
                aria-label="Download CSV"
                variant="contained"
                color="primary"
                startIcon={<CloudDownloadIcon />}
                onClick={() =>  onExportCSV("All_locations.csv", locations)}
            >
                All
            </Button>
            <Button
                className="button-download"
                aria-label="Sync list"
                variant="contained"
                color="primary"
                startIcon={<SyncIcon />}
                onClick={() => resync.setSyncLocations(true)}
            >
                Sync
            </Button>
        </Toolbar>
      </Toolbar>
      <TableContainer>
        <Table>

          {/********************************************************** TABLE HEADER  *******************************************/}
          <TableHead>
            <TableRow>
              <TableHeaderCell 
                align="left"
                text="Name"
                onClick={onSortName}
                isActive={activeSortLabel === 'name'}
                direction={activeSortLabel === 'name' ? sortOrder : 'asc'}
              />
            <TableHeaderCell 
                align="left"
                text="City"
                onClick={ onSortCity}
                isActive={activeSortLabel === 'city'}
                direction={activeSortLabel === 'city' ? sortOrder : 'asc'}
              />

              <TableHeaderCell 
                align="left"
                text="State"
                onClick={onSortState}
                isActive={activeSortLabel === 'state'}
                direction={activeSortLabel === 'state' ? sortOrder : 'asc'}
              />

              <TableHeaderCell 
                align="right"
                text="Review Count"
                onClick={onSortReview}
                isActive={activeSortLabel === 'review_count'}
                direction={activeSortLabel === 'review_count' ? sortOrder : 'asc'}
              />

              <TableHeaderCell 
                align="right"
                text="Rating"
                onClick={onSortRating}
                isActive={activeSortLabel === 'rating'}
                direction={activeSortLabel === 'rating' ? sortOrder : 'asc'}
              />

              
            </TableRow>
          </TableHead>

          {/********************************************************** TABLE BODY  *******************************************/}
          <TableBody>
            {locations && locations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( location => <LocationRow key={location.id} {...location } /> )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination 
                rowsPerPageOptions={[25, 50, 75, 100]}
                page={page}
                onChangePage={ (e, newPage) => setPage(newPage)}
                count={locations ? locations.length : 0}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  )
}

  export {
      LocationsTable
  }
